<template>
  <div id="orderHistory">
    <!-- User section -->
    <UserSection :userId="userId" />
    <!-- Table View -->
    <VueDatatable ref="myTable" :setupTable="setupTable">
      <template v-slot:thead>
        <tr>
          <th class="id-col" style="width: 59px">
            <span>ID</span>
          </th>
          <th style="width: 65px">
            <b-form-select
              style="width: 100%"
              v-model="search.status"
              :options="statusOptions"
              @change="(v) => myTable.searching(v, 1)"
              size="sm"
            />
          </th>
          <th>
            <b-form-select
              style="width: inherit"
              v-model="search.service"
              :options="serviceOptions"
              @change="(v) => myTable.searching(v, 2)"
              size="sm"
            />
          </th>
          <th>IMEI</th>
          <th>Result</th>
          <th>Submited</th>
          <th>Credit</th>
          <th class="none">Notes</th>
          <th>Category</th>
        </tr>
      </template>
    </VueDatatable>
  </div>
</template>

<script>
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable, serviceOptions } from "@/datatable/order.datatable.js";
import { statusOptions } from "@/global/variables.js";
import { BFormSelect } from "bootstrap-vue";
import UserSection from "@/components/UI/UserSection.vue";

export default {
  name: "OrderHistroy",
  data: () => ({
    dataTable: null,
    setupTable,
    search: {
      status: null,
      service: null,
    },
    statusOptions,
    serviceOptions: [],
  }),
  async mounted() {
    this.dataTable = this.$refs.myTable;
    this.serviceOptions = await serviceOptions();
    this.myTable.setup({ url: `/api/orders/${this.$route.params.user_id}` });
  },
  computed: {
    myTable() {
      return this.dataTable || {};
    },
    userId() {
      return this.$route.params.user_id;
    },
  },
  components: {
    VueDatatable,
    BFormSelect,
    UserSection,
  },
};
</script>

<style>
</style>